import { useQuery } from 'react-query';

import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';

import { getEnrollments } from '../controllers/get-enrollments';

export function useEnrollments(programKey?: string) {
  const { userId, jwt } = useUser();
  const clientReady = useClientRender();

  const {
    data: enrollments,
    isLoading: enrollmentsIsLoading,
    isFetched: enrollmentsIsFetched,
  } = useQuery(['enrollments', userId], () => getEnrollments(jwt!), {
    enabled: !!jwt && clientReady,
  });

  return {
    enrollment: enrollments?.find((enrollment) => enrollment.key === programKey),
    enrollments,
    enrollmentsIsLoading,
    enrollmentsIsFetched,
  };
}
