/**
 * TODO: Delete this once Deque has finished their accessibility test
 * This is the Deque test user - they're currently wrapping up an accessibility test and we don't want to update their experience until they're done
 */

import { useUser } from '~/features/user/hooks/use-user';

export function useDashboardExperiment() {
  const { userId } = useUser();

  return {
    hasDashboardExperiment: userId !== 'cf54ee1c-0d73-11ef-bee0-b38d406c12d0',
  };
}
