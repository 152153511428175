import { publicEnv } from '~/features/environment/public';

import { FREE_TRIAL_FEATURE_FLAG_KEY } from '../constants';

const OPTIMIZELY_PROJECT_ID = '23970240852';

export async function getFreeTrialFeatureFlag({
  userKey,
  countryCode,
  hasActiveSubscription,
  hasFocused,
}: {
  countryCode: string;
  userKey: string;
  hasActiveSubscription: boolean;
  hasFocused: boolean;
}) {
  // Note: the proxy .org url is necessary so that this request can be made both from the client and the CF Worker.
  const url = new URL(
    `https://${
      publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' || publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'preview'
        ? 'experiments-api-proxy.udacity-dev.org'
        : 'experiments-api-staging-proxy.udacity-dev.org'
    }/api/v1/users/${userKey}/enabled-features/${FREE_TRIAL_FEATURE_FLAG_KEY}`
  );

  url.searchParams.append(
    'attributes',
    JSON.stringify({
      country_code: countryCode,
      active_subscription: hasActiveSubscription,
      has_focused: hasFocused,
      user_key: userKey,
    })
  );

  const response = await fetch(url.toString(), {
    headers: {
      'Content-Type': 'application/json',
      'X-Optimizely-Project-ID': OPTIMIZELY_PROJECT_ID,
    },
  });

  try {
    const data = (await response.json()) as { key: string; enabled: boolean };
    return data.enabled;
  } catch (error) {
    console.error('Error fetching feature flag', error);
    return false;
  }
}
