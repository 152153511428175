import auth from '@udacity/ureact-hoth';

import { getAnonymousIdCookie } from '~/features/cookies/utils/get-anonymous-id-cookie';
import { publicEnv } from '~/features/environment/public';
import { getUserIdFromJwt } from '~/features/user/utils/get-user-id-from-jwt';
import { sleep } from '~/utils/sleep';

import { getDefaultAnalyticProperties, getGeoAnalyticsProperties } from './utils/get-analytics-properties';
import { getContext } from './utils/get-context';
import { getSegmentUserId } from './utils/get-segment-user-id';
import { SEGMENT_URL, UDACITY_SEGMENT_PROXY_URL } from './constants';

const segmentWriteKey = publicEnv.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

const isEventLoggerOn =
  publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && publicEnv.NEXT_PUBLIC_DEBUG_ANALYTICS;

export async function httpIdentifyEvent(
  traits?: Record<string, any>,
  { sendToSalesforce = false }: { sendToSalesforce?: boolean } = {}
): Promise<{ success: boolean } | undefined> {
  if (typeof window === 'undefined') return;

  const jwt = auth.getJWT();
  const userId = jwt ? getUserIdFromJwt(jwt) : getSegmentUserId();

  if (isEventLoggerOn) {
    console.log(`identify:`, { userId, traits, sendToSalesforce });
  }

  const anonymousId = getAnonymousIdCookie();
  const context = getContext();

  const traitProperties = {
    ...getDefaultAnalyticProperties(),
    ...getGeoAnalyticsProperties(),
    ...traits,
  };

  const integrations = {
    Salesforce: sendToSalesforce,
  };

  const payload = {
    userId: userId ?? null,
    anonymousId,
    integrations,
    traits: traitProperties,
    context,
    writeKey: segmentWriteKey,
    type: 'identify',
  };

  const baseUrl = publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production' ? UDACITY_SEGMENT_PROXY_URL : SEGMENT_URL;

  const response = await fetch(`${baseUrl}/identify`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${segmentWriteKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw Error(`Identify request failed with code: ${response.status}`);
  }

  // Note: this is a bit hacky but we need to add a delay to ensure that the identify call has plenty of time to make its way to Marketo before the track event does.
  // TODO: figure out a better way to do this.
  await sleep(2000);

  return await response.json();
}
