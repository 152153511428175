import { Glyph } from '~/components/glyph';

export const navExperimentData = [
  {
    id: 'learn',
    label: 'Learn',
    navItems: [
      {
        id: 'schools',
        label: 'Schools',
        navItems: [
          {
            id: 'artificial-intelligence',
            label: 'Artificial Intelligence',
            href: '/school/artificial-intelligence',
            iconSlot: <Glyph height={32} name='school-ai-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'autonomous-systems',
            label: 'Autonomous Systems',
            href: '/school/autonomous-systems',
            iconSlot: <Glyph height={32} name='school-autonomous-sys-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'business',
            label: 'Business',
            href: '/school/business',
            iconSlot: <Glyph height={32} name='school-business-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'career-resources',
            label: 'Career Resources',
            href: '/school/career-resources',
            iconSlot: <Glyph height={32} name='hand-plant-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'cloud-computing',
            label: 'Cloud Computing',
            href: '/school/cloud-computing',
            iconSlot: <Glyph height={32} name='school-cloud-computing-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'cybersecurity',
            label: 'Cybersecurity',
            href: '/school/cybersecurity',
            iconSlot: <Glyph height={32} name='school-cybersecurity-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'data-science',
            label: 'Data Science',
            href: '/school/data-science',
            iconSlot: <Glyph height={32} name='school-data-sci-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'executive-leadership',
            label: 'Executive Leadership',
            href: '/school/executive-leadership',
            iconSlot: <Glyph height={32} name='school-executive-leadership-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'product-management',
            label: 'Product Management',
            href: '/school/product-management',
            iconSlot: <Glyph height={32} name='school-product-mgt-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'programming',
            label: 'Programming',
            href: '/school/programming',
            iconSlot: <Glyph height={32} name='school-programming-one' width={32} />,
            styleProps: { fontWeight: '600' },
          },
          {
            id: 'view-full-catalog',
            label: 'View Full Catalog',
            href: '/catalog',
            styleProps: {
              fontWeight: '600',
              border: '1px solid #2015FF',
              borderRadius: '4px',
              padding: '8px',
              justifyContent: 'center',
              color: '#2015FF',
            },
          },
        ],
      },
      {
        id: 'popular',
        label: 'Popular',
        navItems: [
          {
            id: 'generative-ai--nd608',
            label: 'Generative AI',
            href: '/course/generative-ai--nd608',
          },
          {
            id: 'data-scientist-nanodegree--nd025',
            label: 'Data Scientist',
            href: '/course/data-scientist-nanodegree--nd025',
          },
          {
            id: 'data-analyst-nanodegree--nd002',
            label: 'Data Analyst',
            href: '/course/data-analyst-nanodegree--nd002',
          },
          {
            id: 'data-engineer-nanodegree--nd027',
            label: 'Data Engineering with AWS',
            href: '/course/data-engineer-nanodegree--nd027',
          },
          {
            id: 'ai-programming-python-nanodegree--nd089',
            label: 'AI Programming with Python',
            href: '/course/ai-programming-python-nanodegree--nd089',
          },
        ],
      },
      {
        id: 'intro',
        label: 'Intro Courses',
        navItems: [
          {
            id: 'introduction-to-python--cd0024',
            label: 'Introduction to Python',
            href: '/course/introduction-to-python--cd0024',
          },
          {
            id: 'intro-to-programming-nanodegree--nd000',
            label: 'Introduction to Programming',
            href: '/course/intro-to-programming-nanodegree--nd000',
          },
          {
            id: 'introduction-to-sql--cd0021',
            label: 'Introduction to SQL',
            href: '/course/introduction-to-sql--cd0021',
          },
          {
            id: 'programming-for-data-science-nanodegree--nd104',
            label: 'Programming for Data Science with Python',
            href: '/course/programming-for-data-science-nanodegree--nd104',
          },
          {
            id: 'business-analytics-nanodegree--nd098',
            label: 'Business Analytics',
            href: '/course/business-analytics-nanodegree--nd098',
          },
        ],
      },
    ],
  },
  { id: 'catalog', label: 'Catalog', href: '/catalog', active: false },
  {
    id: 'enterprise',
    label: 'Business',
    navItems: [
      { id: 'overview', label: 'Overview', href: '/enterprise/overview' },
      { id: 'resources', label: 'Resources', href: '/resource-center' },
      { id: 'plans', label: 'Compare Plans', href: '/enterprise/plans' },
    ],
  },
  {
    id: 'government',
    label: 'Government',
    href: '/government/overview',
    active: false,
  },
];
